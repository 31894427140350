// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import FurnitureHouesePicker from './FurnitureHouesePicker.component';
import { FURNITURE_HOUSES } from './FurnitureHousePicker.config';

/** @namespace Hoeks/Shipping_furniture_house/Component/FurnitureHouesePicker/Container/FurnitureHouesePickerContainer */
export class FurnitureHouesePickerContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    state = {
        options: []
    }

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    containerProps = () => {
        // isDisabled: this._getIsDisabled()
    };

    componentDidMount() {
        this.prepareSelectOptions();
    }

    componentDidUpdate() {
        const { onSelect, active } = this.props;
        const { options } = this.state;

        if (!options.length) {
            this.prepareSelectOptions();
        }

        if (options.length && active) {
            onSelect(options[0].value);
        }
    }

    prepareSelectOptions() {
        var selectOptions = [];
        FURNITURE_HOUSES.forEach((furnitureHouse, index) => {
            const option = {
                id: index,
                value: furnitureHouse,
                disable: false,
                label: furnitureHouse,
            }
            selectOptions.push(option);
        });
        selectOptions = selectOptions.sort((a, b) => {
            if (a.value < b.value) return -1;
            if (a.value > b.value) return 1;
            return 0;
        });
        this.setState({options: selectOptions});
    }

    render() {
        return (
            <FurnitureHouesePicker
                {...this.state}
                {...this.props}
                {...this.containerFunctions}
                {...this.containerProps()}
            />
        );
    }
}

export default FurnitureHouesePickerContainer;

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import HelloRetailRecommendationBox from './HelloRetailRecommendationBox.component';
import { API_URL } from './HelloRetailRecommendationBox.config';
import { withRouter } from 'react-router';
import ProductListQuery from 'Query/ProductList.query';
import { prepareQuery } from 'SourceUtil/Query';
import { executeGet } from 'SourceUtil/Request';
import { getIndexedProduct } from 'SourceUtil/Product';
import history from 'Util/History';
import { getHRIndexedProduct } from '../../util/HelloRetail';

const LINK_TYPE = 'RECOMMENDATION';

const mapStateToProps = state => ({
    baseUrl: state.ConfigReducer.base_url,
    breadcrumbs: state.BreadcrumbsReducer.breadcrumbs
});

const mapDispatchToProps = dispatch => ({
    dispatch
});

export class HelloRetailRecommendationBoxContainer extends PureComponent {
    componentDidMount(){
        this.getTrackingId();
    }

    getTrackingId() {
        var getTrackingIdTimeout;
        clearTimeout(getTrackingIdTimeout);
        if (window.ADDWISH_PARTNER_NS && this.getCookie('hello_retail_id')) {
            this.loadRecommendations(this.getCookie('hello_retail_id'));
        } else {
            getTrackingIdTimeout = setTimeout(this.getTrackingId.bind(this), 1000);
        }
    }

    getCookie(name) {
        const nameEQ = name + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    componentDidUpdate(prevProps){
        const { ids, category: { id } = {} } = this.props;
        const { ids: prevIds, category: { id: prevId } = {} } = prevProps;
        const {
            linkType,
            emptyResult,
            linkedProducts: {
                [linkType]: {
                    items = []
                } = {}
            }
        } = this.state;

        if (window.ADDWISH_PARTNER_NS && this.getCookie('hello_retail_id') && ( JSON.stringify(ids)!=JSON.stringify(prevIds) || id != prevId)){
            this.loadRecommendations(this.getCookie('hello_retail_id'));
        }
    }

    loadRecommendations(trackingId){
        this.fetchData(trackingId);
    }

    state = {
        linkedProducts: {},
        linkType: LINK_TYPE,
        numberOfProductsToDisplay: 12,
        emptyResult: false
    }

    async fetchData(trackingId){
        const url = this._createRestUrl(trackingId);
        const response = await fetch(url);
        const { result } = await response.json();
        if (!result || !Object.values(result).some(o => !!o.result.length)){
            this.setState({linkedProducts: {}, emptyResult: true});
            return;
        }
        const values = (Object.values(result).reduce(this.reduceProducts, []));
        this.setState({linkedProducts: {[LINK_TYPE]: {total_count: values.length, items: values}}});
        const trackingData = (Object.values(result).reduce(this.reduceValues, {}));
        const skus = Object.keys(trackingData).map(encodeURIComponent);
        if (!skus.length) return;
        executeGet(prepareQuery(this.prepareRequest(skus)), 'HelloRetailProducts', 86400)
        .then((result) => this.onSuccess(result, trackingData))
        .catch(
            (e) => console.log('error', 'Error fetching NewProducts!', e)
        );
    }

    onSuccess(data, trackingData){
        this.setState({linkedProducts: this._processResponse(data, trackingData)});
    }

    reduceProducts(result, value, index){
        const reduced = value.result.reduce((acc, product) => 
        { 
            acc.push(getHRIndexedProduct(product)); 
            return acc;
        },
        []);
        return result.concat(reduced);
    }

    reduceValues(result, value, index){
        const reduced = value.result.reduce((acc, product) => 
        { 
            acc[product.productNumber] = product.url; 
            return acc;
        },
        {});
        return { ...result, ...reduced} 

    }

    reduceSkus(result, value, index){
        return result.concat(value.result.map(product => product.productNumber));
    }

    prepareRequest(skus){
        return [
            ProductListQuery.getQuery({
                args: {
                    filter: {
                        productsSkuArray: skus
                    }
                },
                notRequireInfo: true
            })
        ];
    }

    _createRestUrl(trackingId){
        const { ids, history: { location: {pathname}}, category, baseUrl, productUrl = '', productSku = '' } = this.props;
        const hierarchies = category?'&'+this._getHierarchies(ids[0],category,0):'';
        const productNumbers = productSku ? this._getProductNumbers(ids[0], productSku) : '';
        var path = productUrl ? productUrl.trimStart('/') : '';
        while(path[0] === '/') {
            path = path.substring(1);
        }
        const url = path != '' ? '&url='+encodeURI(`${baseUrl}${path}`) : '';
        return `${API_URL}?trackingUserId=${trackingId}&format=json&ids=${ids.join(',')}${hierarchies}${productNumbers}${url}`;
    }

    _getProductNumbers(id, productSku) {
        const data = `&crawledData[${id}][productNumbers][]=${productSku}`;
        return data;
    }

    _getHierarchies(id, category, index = 0){
        const { breadcrumbs, name } = category;
        const hierarchies = [];
        if (breadcrumbs){
            breadcrumbs.forEach(({ category_name }) => hierarchies.push(category_name));
        }
        hierarchies.push(name);
        const data = hierarchies.map((name) => `crawledData[${id}][hierarchies][${index}][]=${encodeURI(name)}`);
        return data.join('&');
    }

    containerProps() {
        const {
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions
        } = this.state;

        return {
            productCardFunctions: {
                setSiblingsHaveBrands: () => this.setState({ siblingsHaveBrands: true }),
                setSiblingsHavePriceBadge: () => this.setState({ siblingsHavePriceBadge: true }),
                setSiblingsHaveTierPrice: () => this.setState({ siblingsHaveTierPrice: true }),
                setSiblingsHaveConfigurableOptions: () => this.setState({ siblingsHaveConfigurableOptions: true }),
                onActiveImageChange: (id) => this.setState({ activeId: id })
            },
            productCardProps: {
                siblingsHaveBrands,
                siblingsHavePriceBadge,
                siblingsHaveTierPrice,
                siblingsHaveConfigurableOptions
            }
        };
    }

    _processResponse(data, tracking) {
        const { products: { items } } = data;
        const indexedItems = items.map((item, index) => getIndexedProduct(item, item.sku))
        const linkedProducts = indexedItems.reduce((acc, item) => {
            item.clickTrack = this._getTrackingFragment(tracking[item.sku]);
            acc[LINK_TYPE].items.push(item);
            acc[LINK_TYPE].total_count++;
            return acc;
        }, {
            [LINK_TYPE]: { total_count: 0, items: [] },
        });
        return linkedProducts;
    }

    _getTrackingFragment(str){
        return str.split('#aw_source=')[1];
    }


    render() {
        const {
            linkType,
            linkedProducts: {
                [linkType]: {
                    items = []
                } = {}
            }
        } = this.state;

        if (items.length === 0) {
            return null;
        }

        return (
            <HelloRetailRecommendationBox
              { ...this.state }
              { ...this.props }
              { ...this.containerProps() }
            />
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HelloRetailRecommendationBoxContainer)); 